import React from 'react';
import Layout from "../components/layout"
import {
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { createContent } from "../common/Theme"

import "../assets/styles/pages/terms-and-conditions.scss";

const useStyles = makeStyles({
  h1: {
    fontSize: '2.2rem',
    marginBottom: '2rem'
  },
  h3: {
    marginBottom: '1rem',
    textTransform: 'uppercase'
  }
});

const PrivacyPolicyPage = () => {
  const content = createContent();
  const classes = useStyles();

  return (
    <Layout mainClassName='terms-and-conditions'>
      <Container classes={{root: content.content}}>
        <Typography variant='h1' classes={{h1: classes.h1}}>Privacy policy</Typography>

        <Typography>
          Syntactful understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of all of our users and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.
        </Typography>

        <Typography>&nbsp;</Typography>

          <ol className='outer-list'>
            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                Information About Us
              </Typography>

              <Typography>
                Syntactful, Braspenning Matthijs BV
              </Typography>
              <Typography>
                A limited company registered in Belgium under company number 0744.803.018
              </Typography>
              <Typography>
                Registered &amp; Main Trading address: Begijnhof 17, 2320 Hoogstraten, Belgium
              </Typography>
              <Typography>
                Email address: info@syntactful.com
              </Typography>
              <Typography>
                Telephone number: +32 (0)496 68 58 37
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                What Does This Notice Cover?
              </Typography>

              <Typography>
                This Privacy Information explains how we use your personal data: how it is collected, how it is held, and how it is processed. It also explains your rights under the law relating to your personal data.
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                What is Personal Data?
              </Typography>

              <Typography>
                Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) as ‘any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier’.
              </Typography>

              <Typography>
                Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers. The personal data that we use is set out in Part 5, below.
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                What Are My Rights?
              </Typography>

              <Typography>
                Under the GDPR, you have the following rights, which we will always work to uphold:
              </Typography>

              <ol>
                <li>The right to be informed about our collection and use of your personal data. This Privacy Notice should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in Part 11.</li>
                <li>The right to access the personal data we hold about you. Part 10 will tell you how to do this.</li>
                <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 11 to find out more.</li>
                <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we have. Please contact us using the details in Part 11 to find out more.</li>
                <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                <li>The right to data portability. This means that you can ask us for a copy of your personal data held by us to re-use with another service or business in many cases.</li>
                <li>Rights relating to automated decision-making and profiling. Part 6 explains more about how we use your personal data, including profiling.</li>
              </ol>

              <Typography>
                For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 11.
              </Typography>
              <Typography>
                Further information about your rights can also be obtained from the Information Commissioner’s Office or your local Citizens Advice Bureau.
                If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office.
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                What Personal Data Do You Collect?
              </Typography>

              <Typography>
                We may collect some or all of the following personal data (this may vary according to your relationship with us):
              </Typography>

              <ol>
                <li>Name;</li>
                <li>Business Address;</li>
                <li>Business Email address;</li>
                <li>Business Telephone number;</li>
                <li>Business name;</li>
                <li>Job title;</li>
                <li>Information about your marketing preferences;</li>
              </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                How Do You Use My Personal Data?
              </Typography>

              <Typography>
                Under the GDPR, we must always have a lawful basis for using personal data. This may be because the data is necessary for our performance of a contract with you, because you have consented to our use of your personal data, or because it is in our legitimate business interests to use it. Your personal data may be used for one of the following purposes:
              </Typography>

              <ol>
                <li>Providing and managing your account.</li>
                <li>Supplying our products and services to you. Your personal details are required in order for us to enter into a contract with you.</li>
                <li>Personalising and tailoring our products and/or services for you.</li>
                <li>Communicating with you. This may include responding to emails or calls from you.</li>
                <li>Supplying you with information by email and/or post that you have opted-in to (you may unsubscribe or opt-out at any time) by contacting us directly or through our <span><a href="https://weareadaptable.us7.list-manage.com/unsubscribe?u=ee3240393a7674327510f2a9d&amp;id=fe139fbc0b">unsubscribe form</a>.</span></li>
              </ol>

              <Typography>
                With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by email and/or telephone and/or post with information, news, and offers on our products and/or services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt-out.
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                How Long Will You Keep My Personal Data?
              </Typography>

              <Typography>
                We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will, therefore, be kept for the following periods (or, where there is no fixed period, the following factors will be used to determine how long it is kept):
              </Typography>

              <ol>
                <li>If you are a prospective customer and have enquired but not started working with the company, your details will be held for a period of 12 months before being deleted.</li>
                <li>If you are a customer, working with the company, your details will be held for the length of our working relationship. Should this working relationship expire your details will held for up to 5 years after such date before being deleted.</li>
                <li>If you are neither of the above and have signed up to our mailing lists online, your data will be kept for the length you choose to be signed up to our mailing lists. You can unsubscribe at anytime.</li>
              </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                How and Where Do You Store or Transfer My Personal Data?
              </Typography>

              <Typography>
                We may store or transfer some or all of your personal data in countries that are not part of the European Economic Area (the “EEA” consists of all EU member states, plus Norway, Iceland, and Liechtenstein). These are known as “third countries” and may not have data protection laws that are as strong as those in Belgium and/or the EEA. This means that we will take additional steps in order to ensure that your personal data is treated just as safely and securely as it would be within Belgium and under the GDPR including:
              </Typography>

              <Typography>
                We will only store data by product and/or services that are compatible and comply fully with GDPR policy regardless of their geographical location.
              </Typography>

              <Typography>
                The security of your personal data is essential to us, and to protect your data, we take a number of important measures, including the following:
              </Typography>

              <ol>
                <li>2 step authentication is mandatory to access data.</li>
                <li>Data held, is stored and protected with the latest forms of encryption.</li>
              </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                Do You Share My Personal Data?
              </Typography>

              <Typography>
                We will not share any of your personal data with any third parties for any purposes, subject to one important exception.
              </Typography>

              <Typography>
                In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                How Can I Access My Personal Data?
              </Typography>

              <Typography>
                If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.
              </Typography>

              <Typography>
                All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 11.
              </Typography>

              <Typography>
                There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.
              </Typography>

              <Typography>
                We will respond to your subject access request within 30 days. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                How Do I Contact You?
              </Typography>

              <Typography>
                To contact us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details:
              </Typography>

              <ol>
                <li>Email address: info@syntactful.com</li>
                <li>Telephone number: +32 (0)496 68 58 37</li>
                <li>Postal Address: Begijnhof 17, 2320 Hoogstraten, Belgium</li>
              </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>
                Changes to this Privacy Notice
              </Typography>

              <Typography>
                We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.
              </Typography>

              <Typography>
                Any changes will be made available on this page where this text is displayed.
              </Typography>

              <Typography>&nbsp;</Typography>
            </li>
          </ol>

        <Typography>
          Last updated: December 2021
        </Typography>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicyPage;
